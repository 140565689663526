import axios from "axios";
import React from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import BaseUrl from "../../../BaseURL/BaseUrl";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import Layout from "../../../layout/Index";
import "../../../pages/pages.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";

const AddSubAdmin = () => {
  const [fname, setFname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState('');

  const history = useHistory();
  const token = localStorage.getItem("accesstoken");  

  const addapi = () => {
    setLoader(true);
    const data = new FormData();
    data.append("name", fname);
    data.append("email", email);
    data.append("password", password);

    var config = {
      method: "POST",
      url: `${BaseUrl.baseurl}/subadmin`,
      data: data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        console.log(response, "api");
        if (response.data.status === true) {
          setLoader(false);
          toast.success(response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          history.push("/subAdmin");
        } else {
          alert("else");
          setLoader(false);
        }
      })
      .catch(function (error) {
        setError(error?.response?.data?.errors)
        setLoader(false);
      });
    };
    console.log(error, "error");

  

  return (
    <>
      <Layout>
        <Head title="Add Sub-Admin" />
        <Content>
          <div className="container-fluid card rounded boxsh py-3">
            <div className="row justify-content-center p-3">
              <div className="col-md-6 boxsh ">
                <div className="card  px-2 py-4 rounded-4">
                  <h3 className="text-theme text-center py-2 ">ADD SUB-ADMIN</h3>
                  <div className="form-group">
                    <label for="exampleInputEmail1">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter First Name"
                      onChange={(e) => setFname(e.target.value)}
                    />
                    <p className="text-danger">{error?.fname}</p>
                  </div>
                 
                  <div className="form-group">
                    <label for="exampleInputEmail1">Email address</label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <p className="text-danger">{error?.email}</p>
                  </div>
                  <div className="form-group">
                    <label for="exampleInputPassword1">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <p className="text-danger">{error?.password}</p>
                  </div>
                  <button
                    className="btn btn-theme w-50 mx-auto d-block d-flex justify-content-center align-items-center"
                    onClick={addapi}
                  >
                    Add &nbsp;&nbsp;
                    {loader === true ? (
                      <CircularProgress style={{ height: "20px", width: "20px", color: "white" }} />
                    ) : null}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default AddSubAdmin;
