import React from "react";
import Logo1 from "../../images/logo.png";
// import LogoDark2x from "../../images/logo-dark2x.png";
import {Link} from "react-router-dom";
import '../../pages/pages.css';

const Logo = () => {
  return (
    <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link d-block mx-auto w-100">
      <img className="logo-img" src={Logo1} alt="logo" />
    </Link>
  );
};

export default Logo;
