import React from "react";
import { Switch, Route } from "react-router-dom";
import Login from "./pages/Admin/auth/Login";
import Homepage from "./pages/Homepage";
import SubAdmin from "./pages/Admin/Subadmin/Subadmin";
import AddSubAdmin from "./pages/Admin/Subadmin/AddSubAdmin";
import EditSumAdmin from "./pages/Admin/Subadmin/EditSumAdmin";
import Doctor from "./pages/Admin/Doctor/Doctor";
import EditDoctor from "./pages/Admin/Doctor/EditDoctor";
import AddDoctor from "./pages/Admin/Doctor/AddDoctor";
import Welcome from "./pages/Welcome";
import Contact from "./pages/Admin/Contact/Contact";
import Dentist_Contact from "./pages/Admin/Dentist_Contact/Contact";
import './pages/Admin/Research/Editor.css'
import 'react-quill/dist/quill.snow.css';
import Faq from "./pages/Admin/FAQ/Faq";
import AddFaq from "./pages/Admin/FAQ/Add";
import EditFaq from "./pages/Admin/FAQ/Edit";

const App = () => {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Welcome} />
        <Route exact path="/admin/login" component={Login} />
        <Route exact path="/admin/dashbaord" component={Homepage} />
        <Route exact path="/subAdmin" component={SubAdmin} />
        <Route exact path="/addsubAdmin" component={AddSubAdmin} />
        <Route exact path="/editsubAdmin/:id" component={EditSumAdmin} />
        <Route exact path="/Dentist" component={Doctor} />
        <Route exact path="/addDentist" component={AddDoctor} />
        <Route exact path="/editDentist/:id" component={EditDoctor} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/dentistcontact" component={Dentist_Contact} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/addfaq" component={AddFaq} />
        <Route exact path="/editfaq/:id" component={EditFaq} />
      </Switch>
    </>
  );
};
export default App;
