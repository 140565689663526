import React from "react";
import { Link } from "react-router-dom";
import admin from "../images/add-user.png";

const Welcome = () => {
  return (
    <div className="bg-wlcome h-100vh d-flex justify-content-center align-items-center">
      <div className="container">
        <div className="py-3">
          <div className="header pb-5">
            <h1 className="text-center font welcome">Welcome Dentective</h1>
          </div>
          <div className="card-body">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6">
                <div className="card custom_card border tattisty">
                  <h4 className=" text-center pt-3" style={{color: "#0085e6"}}>Super Admin</h4>
                  <hr />
                  <div className="d-flex justify-content-center">
                    <img src={admin} alt="super admin" className="image-admin" />
                  </div>
                  <Link to="/admin/login" className="btn btn-theme  my-5 mx-auto text-center">
                    Super Admin
                  </Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card custom_card border tattisty">
                  <h4 className="  text-center pt-3" style={{color: "#0085e6"}}>Sub Admin</h4>
                  <hr />
                  <div className="d-flex justify-content-center">
                    <img src={admin} alt="super admin" className="image-admin" />
                  </div>
                  <Link to="/admin/login" className="btn btn-theme  my-5 mx-auto text-center">
                  Sub Admin
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
