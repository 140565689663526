/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import BaseUrl from "../../../BaseURL/BaseUrl";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import Layout from "../../../layout/Index";
import "../../../pages/pages.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
} from "@react-google-maps/api";
import { useRef } from "react";

const containerStyle = {
  width: "100%",
  height: "500px",
};



const EditDoctor = () => {
  const [FirstName, setFirstName] = useState("");
  const [phone, setPhone] = useState("");
  const [LastName, setLastName] = useState("");
  const [Address, setAddress] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [Zip_Code, setZip_Code] = useState("");
  const [Country, setCountry] = useState("");
  const [Email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState('');
  const [PlaceName, setPlaceName] = useState();
  const [organization, setOrganization] = useState("")
  const [Desc, setDesc] = useState("")
  const [Position, setPosition] = useState({
    lat: 0,
    lng: 0,
  });
  const [selects, setSelects] = useState([
    { id: 1, category: "", subCategory: "", fee: "" },
  ]);
  const history = useHistory();
  const { id } = useParams();
  const token = localStorage.getItem("accesstoken");

  const ShowDoctor = () => {
    setLoader(true)
    var config = {
      method: "get",
      url: `${BaseUrl.baseurl}/doctor/${id}`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config).then((response) => {
      setLoader(false)
      console.log(response,"show data");
      const { last_name, phone, email, city, country, name, organization, state, address, zip_code, lat, lng, bio,categories} = (response?.data?.doctor)
      setFirstName(name)
      setPhone(phone)
      setLastName(last_name)
      setEmail(email)
      setOrganization(organization)
      setAddress(address)
      setSelects(categories)
      setCity(city)
      setCountry(country)
      setState(state)
      setZip_Code(zip_code)
      setDesc(bio)
      setPosition({ lat: parseFloat(lat), lng: parseFloat(lng) })
    }).catch((error) => {
      setLoader(false)
      console.log(error);
    })

  }

  useEffect(() => {
    ShowDoctor();
  }, [])


  //google map work

  const { isLoaded } = useJsApiLoader({
    // id: "google-map-script",
    googleMapsApiKey: "AIzaSyAt9IOK_D-YxRKKyZgzwyJxxWO503VzHEQ",
    libraries: ["places"],
  });
  const autoCompleteRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    console.log("useeffect", +1);
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const Place = await autoCompleteRef.current.getPlace();
      // console.log(place.geometry.location.lat(),"place");
      // console.log(place.geometry.location.lng(),"place");
      setPosition({
        lat: Place.geometry.location.lat(),
        lng: Place.geometry.location.lng(),
      });
      // setPlaceName(place.formatted_address);
      setPlaceName(Place.name);
      console.log(Place, "place");
    });
  }, [Position]);

  //google map work

  const handleCloneSelects = () => {
    setSelects((prevSelects) => [
      ...prevSelects,
      { id: prevSelects.length + 1, category: "", subCategory: null, fee: "" },
    ]);
  };

  const handleSelectChange = (event, id, field) => {
    const updatedSelects = [...selects];
    const index = updatedSelects.findIndex((select) => select.id === id);
    updatedSelects[index][field] = event.target.value;
    setSelects(updatedSelects);
  };
  // console.log(selects?.map((e, i) => (`categories[]`, e)
  // ), "clone");
  const handleRemoveSelect = (id) => {
    const updatedSelects = selects.filter((select) => select.id !== id);
    setSelects(updatedSelects);
  };


  const editapi = () => {
    setLoader(true);
    try {
      var data = new FormData();
      data.append("name", FirstName);
      data.append("phone", phone);
      data.append("last_name", LastName);
      data.append("email", Email);
      data.append("address", PlaceName ? PlaceName : Address);
      data.append("city", City);
      data.append("state", State);
      data.append("zip_code", Zip_Code);
      data.append("country", Country);
      data.append("lng", Position.lng);
      data.append("lat", Position.lat);
      data.append("bio", Desc);
      const requestHeaders = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'X-Http-Method-Override': 'PUT'
      };
      const config = {
        method: "POST",
        url: `${BaseUrl.baseurl}/doctor/${id}`,
        data: data,
        headers: requestHeaders
      };

      axios(config)
        .then(function (response) {
          console.log(response, "api");
          if (response.data.status == 1) {
            setLoader(false);
            toast.success(response?.data?.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            history.push("/Dentist");
          } else {
            setLoader(false);
            toast.error(response?.data?.error, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        })
        .catch((errors) => {
          setLoader(false);
          setError(errors?.response?.data?.error);
        });
    } catch (errors) {
      setLoader(false);
      console.log(errors, "error");
      setError(errors?.response?.data?.error);
      toast.error(errors?.response?.data?.error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <>
      <Layout>
        <Head title="Update Doctor" />
        <Content>
          <div className="container-fluid card rounded boxsh py-3">
            <div className="row justify-content-center p-3">
              <div className="col-md-12">
                <div className="">
                  <h3 className="text-theme text-center py-2 ">Update Dentist</h3>
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label for="exampleInputEmail1">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter First Name"
                        value={FirstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <p className="text-theme">{error?.user_name}</p>
                    </div>
                    <div className="form-group col-md-4">
                      <label>Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        value={LastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      <p className="text-theme">{error?.setLastName}</p>
                    </div>
                    <div className="form-group col-md-4">
                      <label for="phone">Phone</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="Phone"
                        placeholder="Enter Phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      <p className="text-theme">{error?.phone}</p>
                    </div>

                  </div>
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={Email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <p className="text-theme">{error?.Email}</p>
                    </div>
                    <div className="form-group col-md-4">
                      <label>State</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="State"
                        value={State}
                        onChange={(e) => setState(e.target.value)}
                      />
                      <p className="text-theme">{error?.State}</p>
                    </div>
                    <div className="form-group col-md-4">
                      <label>Organization</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Organization"
                        value={organization}
                        onChange={(e) => setOrganization(e.target.value)}
                      />
                      <p className="text-theme">{error?.Organization}</p>
                    </div>

                  </div>
                  <div className="row">
                    <div className="form-group col-md-12">
                      <label>Street Address</label>
                      <input
                        type="text"
                        ref={inputRef}
                        className="form-control"
                        placeholder="Street Address"
                        //  value={Address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                      <p className="text-theme">{error?.Address}</p>
                    </div>


                  </div>
                  <div className="" style={{}}>
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={{
                        lat: Position.lat,
                        lng: Position.lng
                      }}
                      zoom={10}
                      options={{
                        zoomControl: true,
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                      }}
                    >
                      <MarkerF
                        position={{
                          lat: Position.lat,
                          lng: Position.lng
                        }}
                      />

                      {/* <MarkerF
                      // key={index}
                        position={{
                          lat: Position[0]?.lat,
                          lng: Position[0]?.lng,
                        }}
                      /> */}
                    </GoogleMap>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label for="exampleInputPassword1">City</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="City"
                        value={City}
                        onChange={(e) => setCity(e.target.value)}
                      />
                      <p className="text-theme">{error?.password}</p>
                    </div>
                    <div className="form-group col-md-4">
                      <label>Country</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Country"
                        value={Country}
                        onChange={(e) => setCountry(e.target.value)}
                      />
                      <p className="text-theme">{error?.Country}</p>
                    </div>
                    <div className="form-group col-md-4">
                      <label>Zip Code</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Zip Code"
                        value={Zip_Code}
                        onChange={(e) => setZip_Code(e.target.value)}
                      />
                      <p className="text-theme">{error?.Zip_Code}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <label>Bio</label>
                      <textarea
                        placeholder="Enter Bio...."
                        rows={5}
                        cols={120}
                        className="p-3 form-control mb-2"
                        value={Desc}
                        onChange={(e) => setDesc(e.target.value)}
                      ></textarea>
                    </div>
                  </div>

                  <button
                    className="btn btn-outline-success mb-3"
                    onClick={handleCloneSelects}
                  >
                    Add categories +
                  </button>
                  {selects.map((select) => (
                    <div key={select.id} className="row">
                      <div className="col-md-4">
                        <select
                          value={select.category}
                          className="form-control"
                          onChange={(e) => {
                            handleSelectChange(e, select.id, "category");
                          }}
                        >
                          <option selected>Category</option>
                          <option value="Anesthesia - General">
                            Anesthesia - General
                          </option>
                          <option value="Fillings">Fillings</option>
                          <option value="Periodontal Services">
                            Periodontal Services
                          </option>
                          <option value="Crowns, Inlays and Onlays">
                            Crowns, Inlays and Onlays
                          </option>

                          <option value="Medications">Medications</option>
                          <option value="Preventative">Preventative</option>
                          <option value="Diagnostic Services">
                            Diagnostic Services
                          </option>
                          <option value="Other Common Services, Materials">
                            Other Common Services, Materials
                          </option>
                          <option value="Prosthetic Services">
                            Prosthetic Services
                          </option>
                          <option value="Root Canal">Root Canal</option>
                          <option value="Surgery">Surgery</option>
                          <option value="X-Rays">X-Rays</option>
                          <option value="Tooth Extraction">
                            Tooth Extraction
                          </option>
                        </select>
                      </div>
                      <div className="col-md-4">
                        <select
                          value={select.subCategory}
                          className="form-control"
                          onChange={(e) =>
                            handleSelectChange(e, select.id, "subCategory")
                          }
                        >
                          <option value="">Sub Catgorey</option>
                          <option value="Crowns Used to Replace Missing Teeth">
                            Crowns Used to Replace Missing Teeth
                          </option>
                          <option value="Veneers">Veneers</option>
                          <option value="Crowns Used to Replace Tooth Structure">
                            Crowns Used to Replace Tooth Structure
                          </option>
                          <option value="Inlays and Onlays">
                            Inlays and Onlays
                          </option>
                          <option value="Exams">Exams</option>
                          <option value="Risk">Risk</option>
                          <option value="Tests">Tests</option>
                          <option value="Bridges & Implants">
                            Bridges & Implants
                          </option>
                          <option value="Denture, Complete">
                            Denture, Complete
                          </option>
                          <option value="Denture, Partial">
                            Denture, Partial
                          </option>
                          <option value="Apexification, Pulp regeneration">
                            Apexification, Pulp regeneration
                          </option>
                          <option value="root canal therapy">
                            root canal therapy
                          </option>
                          <option value="X-ray - complex">
                            X-ray - complex
                          </option>
                          <option value="X-Ray - Jaws">X-Ray - Jaws</option>
                          <option value="X-Ray - Teeth">X-Ray - Teeth</option>
                          <option value="Bone Surgery">Bone Surgery</option>
                          <option value="Grafts (Bone and Soft Tissue)">
                            Grafts (Bone and Soft Tissue)
                          </option>
                          <option value="Soft Tissue (Gum)">
                            Soft Tissue (Gum)
                          </option>
                        </select>
                      </div>
                      <div className="form-group col-md-2">
                        <input
                          type="number"
                          className="form-control"
                          value={select.fee}
                          placeholder="Fee"
                          onChange={(e) => handleSelectChange(e, select.id, "fee")}
                        />
                        <p className="text-theme">{error?.State}</p>
                      </div>
                      <div className="col-md-2">
                        <button
                          className="btn btn-outline-danger mb-3"
                          onClick={() => handleRemoveSelect(select.id)}
                        >
                          -
                        </button>
                      </div>
                    </div>
                  ))}

                  <button
                    className="btn btn-theme w-25 mx-auto d-block d-flex justify-content-center align-items-center"
                    onClick={editapi}
                  >
                    Update &nbsp;&nbsp;
                    {loader === true ? (
                      <CircularProgress style={{ height: "20px", width: "20px", color: "white" }} />
                    ) : null}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </>
  )
}

export default EditDoctor