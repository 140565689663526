const menu = [
  [
    {
      icon: "user-check",
      text: "Admin Dashboard",
      link: "/admin/dashbaord",
    },
    {
      icon: "users-fill",
      text: "Sub Admin",
      link: "/subAdmin",
    },
    {
      icon: "user-round",
      text: "Dentist",
      link: "/Dentist",
    },
    {
      icon: "user-round",
      text: "Contact",
      link: "/contact",
    },
    {
      icon: "user-round",
      text: "Dentist Contact List",
      link: "/dentistcontact",
    },
    {icon: "question-alt",
    text: "FAQ",
    link: "/faq",
  }
],
[
  {
    icon: "user-round",
    text: "Dentist",
    link: "/Dentist",
  },

]
      

];

export default menu;
