/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable eqeqeq */
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import BaseUrl from "../../../BaseURL/BaseUrl";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import Layout from "../../../layout/Index";
import "../../../pages/pages.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import { useEffect } from "react";
import { useRef } from "react";

const containerStyle = {
  width: "100%",
  height: "500px",
};

const AddDoctor = () => {
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Address, setAddress] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [Zip_Code, setZip_Code] = useState("");
  const [Country, setCountry] = useState("");
  const [Organization, setOrganization] = useState("");
  const [Email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [PlaceName, setPlaceName] = useState();
  const [Desc, setDesc] = useState("");
  const [Phone, setPhone] = useState();
  const [Position, setPosition] = useState({
    lat: 0,
    lng: 0,
  });
  const [selects, setSelects] = useState([
    { id: 1, category: "", subCategory: "", fee: "" },
  ]);
  const [fee, setFee] = useState();

  const history = useHistory();
  const token = localStorage.getItem("accesstoken");


  const addapi = () => {
    setLoader(true);
    const data = new FormData();
    data.append("first_name", FirstName);
    data.append("last_name", LastName);
    data.append("email", Email);
    data.append("address", PlaceName ? PlaceName : Address);
    data.append("state", State);
    data.append("city", City);
    data.append("zip_code", Zip_Code);
    data.append("country", Country);
    data.append("organization", Organization);
    data.append("lng", Position.lng);
    data.append("lat", Position.lat);
    data.append("bio", Desc);
    data.append("phone", Phone);
    selects?.map((e, i) => (
      data.append(`categories[${[i]}][category]`, e.category),
      data.append(`categories[${[i]}][subCategory]`, e.subCategory),
      data.append(`categories[${[i]}][fee]`, e.fee)
    ))
    // data.append("profile_photo", Profile);

    var config = {
      method: "POST",
      url: `${BaseUrl.baseurl}/doctor`,
      data: data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        console.log(response, "api");
        if (response.data.status == 1) {
          setLoader(false);
          toast.success(response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          history.push("/Dentist");
        } else {
          setLoader(false);
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log(error,"error")
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setError(error?.response?.data?.errors);
        setLoader(false);
      });
  };


  //google map work

  const { isLoaded } = useJsApiLoader({
    // id: "google-map-script",
    googleMapsApiKey: "AIzaSyAt9IOK_D-YxRKKyZgzwyJxxWO503VzHEQ",
    libraries: ["places"],
  });
  const autoCompleteRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    console.log("useeffect", +1);
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const Place = await autoCompleteRef.current.getPlace();
      // console.log(place.geometry.location.lat(),"place");
      // console.log(place.geometry.location.lng(),"place");
      setPosition({
        lat: Place.geometry.location.lat(),
        lng: Place.geometry.location.lng(),
      });
      // setPlaceName(place.formatted_address);
      setPlaceName(Place.name);
      console.log(Place, "place");
    });
  }, [Position]);


  //google map work

  const handleCloneSelects = () => {
    setSelects((prevSelects) => [
      ...prevSelects,
      { id: prevSelects.length + 1, category: "", subCategory: null, fee: "" },
    ]);
  };

  const handleSelectChange = (event, id, field) => {
    const updatedSelects = [...selects];
    const index = updatedSelects.findIndex((select) => select.id === id);
    updatedSelects[index][field] = event.target.value;
    setSelects(updatedSelects);
  };
  console.log(selects?.map((e, i) => (`categories[]`, e)
  ),"clone");
  const handleRemoveSelect = (id) => {
    const updatedSelects = selects.filter((select) => select.id !== id);
    setSelects(updatedSelects);
  };

  return (
    <>
      <Layout>
        <Head title="Add Dentist" />
        <Content>
          <div className="container-fluid card rounded boxsh py-3">
            <div className="row justify-content-center p-3">
              <div className="col-md-12">
                <div className="">
                  <h3 className="text-theme text-center py-2 ">Add Dentist</h3>
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label for="exampleInputEmail1">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter First Name"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <p className="text-theme">{error?.user_name}</p>
                    </div>

                    <div className="form-group col-md-4">
                      <label>Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      <p className="text-theme">{error?.setLastName}</p>
                    </div>
                    <div className="form-group col-md-4">
                      <label>Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      <p className="text-theme">{error?.setLastName}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <p className="text-theme">{error?.Email}</p>
                    </div>
                    <div className="form-group col-md-4">
                      <label>State</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="State"
                        onChange={(e) => setState(e.target.value)}
                      />
                      <p className="text-theme">{error?.State}</p>
                    </div>
                    <div className="form-group col-md-4">
                      <label>Organization</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Organization"
                        onChange={(e) => setOrganization(e.target.value)}
                      />
                      <p className="text-theme">{error?.Organization}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div
                      className="col-md-12 mb-4"
                      style={{
                        display: "inline",
                        position: "relative",
                        zIndex: 1111,
                      }}
                    >
                      <label>Street Address</label>
                      <input
                        ref={inputRef}
                        // type="text"
                        className="form-control"
                        placeholder="Street Address"
                        // value={Adress}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>

                  </div>
                  <div className="" style={{}}>
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={{
                        lat: Position.lat,
                        lng: Position.lng,
                      }}
                      zoom={10}
                      options={{
                        zoomControl: true,
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                      }}
                    >
                      <MarkerF
                        position={{
                          lat: Position.lat,
                          lng: Position.lng,
                        }}
                      />

                      {/* <MarkerF
                      // key={index}
                        position={{
                          lat: Position[0]?.lat,
                          lng: Position[0]?.lng,
                        }}
                      /> */}
                    </GoogleMap>
                  </div>
                  <div className="row mt-3">
                    <div className="form-group col-md-4">
                      <label for="exampleInputPassword1">City</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="City"
                        onChange={(e) => setCity(e.target.value)}
                      />
                      <p className="text-theme">{error?.password}</p>
                    </div>
                    <div className="form-group col-md-4">
                      <label>Country</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Country"
                        onChange={(e) => setCountry(e.target.value)}
                      />
                      <p className="text-theme">{error?.Country}</p>
                    </div>
                    <div className="form-group col-md-4">
                      <label>Zip Code</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Zip Code"
                        onChange={(e) => setZip_Code(e.target.value)}
                      />
                      <p className="text-theme">{error?.Zip_Code}</p>
                    </div>
                  </div>      

                  <div className="row">
                    <div className="col-md-12">
                      <label>Bio</label>
                      <textarea
                        placeholder="Enter Bio...."
                        rows={5}
                        cols={120}
                        className="p-3 form-control mb-2"
                        onChange={(e) => setDesc(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <button
                    className="btn btn-outline-success mb-3"
                    onClick={handleCloneSelects}
                  >
                    Add categories +
                  </button>
                  {selects.map((select) => (
                    <div key={select.id} className="row">
                      <div className="col-md-4">
                        <select
                          value={select.category}
                          className="form-control"
                          onChange={(e) => {
                            handleSelectChange(e, select.id, "category");
                          }}
                        >
                          <option selected>Category</option>
                          <option value="Anesthesia - General">
                            Anesthesia - General
                          </option>
                          <option value="Fillings">Fillings</option>
                          <option value="Periodontal Services">
                            Periodontal Services
                          </option>
                          <option value="Crowns, Inlays and Onlays">
                            Crowns, Inlays and Onlays
                          </option>

                          <option value="Medications">Medications</option>
                          <option value="Preventative">Preventative</option>
                          <option value="Diagnostic Services">
                            Diagnostic Services
                          </option>
                          <option value="Other Common Services, Materials">
                            Other Common Services, Materials
                          </option>
                          <option value="Prosthetic Services">
                            Prosthetic Services
                          </option>
                          <option value="Root Canal">Root Canal</option>
                          <option value="Surgery">Surgery</option>
                          <option value="X-Rays">X-Rays</option>
                          <option value="Tooth Extraction">
                            Tooth Extraction
                          </option>
                        </select>
                      </div>
                      <div className="col-md-4">
                        <select
                          value={select.subCategory}
                          className="form-control"
                          onChange={(e) =>
                            handleSelectChange(e, select.id, "subCategory")
                          }
                        >
                          <option value="">Sub Catgorey</option>
                          <option value="Crowns Used to Replace Missing Teeth">
                            Crowns Used to Replace Missing Teeth
                          </option>
                          <option value="Veneers">Veneers</option>
                          <option value="Crowns Used to Replace Tooth Structure">
                            Crowns Used to Replace Tooth Structure
                          </option>
                          <option value="Inlays and Onlays">
                            Inlays and Onlays
                          </option>
                          <option value="Exams">Exams</option>
                          <option value="Risk">Risk</option>
                          <option value="Tests">Tests</option>
                          <option value="Bridges & Implants">
                            Bridges & Implants
                          </option>
                          <option value="Denture, Complete">
                            Denture, Complete
                          </option>
                          <option value="Denture, Partial">
                            Denture, Partial
                          </option>
                          <option value="Apexification, Pulp regeneration">
                            Apexification, Pulp regeneration
                          </option>
                          <option value="root canal therapy">
                            root canal therapy
                          </option>
                          <option value="X-ray - complex">
                            X-ray - complex
                          </option>
                          <option value="X-Ray - Jaws">X-Ray - Jaws</option>
                          <option value="X-Ray - Teeth">X-Ray - Teeth</option>
                          <option value="Bone Surgery">Bone Surgery</option>
                          <option value="Grafts (Bone and Soft Tissue)">
                            Grafts (Bone and Soft Tissue)
                          </option>
                          <option value="Soft Tissue (Gum)">
                            Soft Tissue (Gum)
                          </option>
                        </select>
                      </div>
                      <div className="form-group col-md-2">
                        <input
                          type="number"
                          className="form-control"
                          value={select.fee}
                          placeholder="Fee"
                          onChange={(e) => handleSelectChange(e, select.id, "fee")}
                        />
                        <p className="text-theme">{error?.State}</p>
                      </div>
                      <div className="col-md-2">
                        <button
                          className="btn btn-outline-danger mb-3"
                          onClick={() => handleRemoveSelect(select.id)}
                        >
                          -
                        </button>
                      </div>
                    </div>
                  ))}
                  <button
                    className="btn btn-theme w-25 mx-auto d-block d-flex justify-content-center align-items-center"
                    onClick={addapi}
                  >
                    Add &nbsp;&nbsp;
                    {loader === true ? (
                      <CircularProgress
                        style={{
                          height: "20px",
                          width: "20px",
                          color: "white",
                        }}
                      />
                    ) : null}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default AddDoctor;
