/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import Layout from "../../../layout/Index";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "../../../pages/pages.css";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { AiOutlineDownload } from 'react-icons/ai';
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import BaseUrl from "../../../BaseURL/BaseUrl";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  ImportExportIcon: forwardRef((props, ref) => (
    <ImportExportIcon {...props} ref={ref} />
  )),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Doctor = () => {
  const token = localStorage.getItem("accesstoken");
  const columns = [
    { title: "S.No", field: "id", cellStyle: { width: "5%" } },
    { title: "Name", field: "first_name", cellStyle: { width: "10%" } },
    { title: "Email", field: "email", cellStyle: { width: "10%" } },
    { title: "Phone", field: "phone", cellStyle: { width: "10%" } },
    {
      title: "Organization",
      field: "organization",
      cellStyle: { width: "10%" },
    },
    { title: "bio", field: "bio", cellStyle: { width: "25%" } },
    {
      title: "Status",
      field: "status", // Assuming 'status' is the correct field to use
      cellStyle: { width: "5%" },
      render: (rowData) => (
        <FormControlLabel
          control={
            <Switch
              defaultChecked={rowData?.is_active === 1 ? true : false} // Assuming 'status' is a boolean value
              inputProps={{ "aria-label": "ant design" }}
              onChange={() => active(rowData.id)}
            />
          }
        />
      ),
    },
  ];
  const classes = useStyles();
  const history = useHistory();

  const [loader, setLoaders] = useState(false);
  const [, setError] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [excel, setExcel] = useState(null);
  const [api, setApi] = useState([]);

  const DoctorApi = () => {
    setLoaders(true);

    const token = localStorage.getItem("accesstoken");
    var config = {
      method: "get",
      url: `${BaseUrl.baseurl}/doctor`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "Doctor");
        setApi(response?.data?.data);
        setLoaders(false);
      })
      .catch(function (error) {
        setLoaders(false);

        console.log(error);
      });
  };
  console.log("subadminApi ", api);

  useEffect(() => {
    DoctorApi();
  }, []);

  const [, setData] = useState();
  const deldata = (rowData) => {
    setLoaders(true);
    try {
      const token = localStorage.getItem("accesstoken");
      var config = {
        method: "delete",
        url: `${BaseUrl.baseurl}/doctor/${rowData.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      axios(config).then(function (response) {
        setData(response.data);
        console.log(response, "wworking");
        setLoaders(false);
        if (response.data?.status === true) {
          Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover Dentist!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              toast.success("successfully delete", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              DoctorApi();
            } else {
            }
          });
        }
      });
    } catch (error) {
      setLoaders(false);
    }
  };
  //active/deactive api
  const active = (id) => {
    setLoaders(true);

    const token = localStorage.getItem("accesstoken");
    var config = {
      method: "GET",
      url: `${BaseUrl.baseurl}/doctor/active/${id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "response");
        if (response?.data?.status === true) {
          setLoaders(false);
          toast.success(response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          DoctorApi();
        } else {
          setLoaders(false);
          toast.error("something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          DoctorApi();
        }
      })
      .catch(function (error) {
        setLoaders(false);
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        DoctorApi();
        console.log(error);
      });
  };

  const Fileupload = () => {
    setLoaders(true);
    const data = new FormData();
    data.append("file", excel);

    var config = {
      method: "POST",
      url: `${BaseUrl.baseurl}/import`,
      data: data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        console.log(response, "api");
        if (response.data.status == 1) {
          setLoaders(false);
          toast.success(response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setModalShow(false)
          history.push("/Dentist");
        } else {
          alert("else");
          setLoaders(false);
          setModalShow(false)
        }
      })
      .catch(function (error) {
        setError(error?.response?.data?.errors);
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setLoaders(false);
        setModalShow(false)
      });
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = 'https://dentectiveapi.dev-sh.xyz/Sample.xlsx';
    link.setAttribute('download', true);
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Layout>
      <Head title="Dentist Dashboard" />
      <Content>
        {loader === true ? (
          <Backdrop className={classes.backdrop} open={loader}>
            <CircularProgress color="red" />
          </Backdrop>
        ) : null}
        <MaterialTable
          icons={tableIcons}
          title="Dentist"
          columns={columns}
          data={api}
          actions={[
            {
              icon: () =>  <AiOutlineDownload color="#0a549c" className="theme-text" />,
              tooltip: "Download Document",
              isFreeAction: true,
              onClick: () => { handleDownload()},
            },
            {
              icon: () => <ImportExportIcon className="text-theme" />,
              tooltip: "Upload Document",
              isFreeAction: true,
              onClick: () => {setModalShow(true)},
            },
            {
              icon: () => <AddBox className="text-theme" />,
              tooltip: "Add Dentist",
              isFreeAction: true,
              onClick: (event) => {
                history.push("/addDentist");
              },
            },

            {
              icon: () => <Edit className="text-theme " />,
              tooltip: "Edit Doctor",
              onClick: (event, rowData) =>
                history.push("/editDentist/" + rowData.id),
            },
            (rowData) => ({
              icon: () => <DeleteOutline className="text-theme" />,
              tooltip: "Delete Dentist",
              onClick: (event, rowData) => deldata(rowData),
            }),
          ]}
          options={{
            actionsColumnIndex: -1,
          }}
        />
        <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Upload  Document
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="file"
            className="form-control"
            onChange={(e) => setExcel(e.target.files[0])}
            required
          />
         
        
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>Close</Button>
          <Button onClick={() => Fileupload()}>
            {loader ? (
              <CircularProgress
                      style={{
                        height: "20px",
                        width: "20px",
                        color: "white",
                      }}
                    />
            ) : (
              "Upload"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      </Content>
    </Layout>
  );
};

export default Doctor;
