
const Card = [
  [
    {
      icon: "users-fill",
      text: "Super Admin",
      link: "/admin/dashbaord",
    },
    {
      icon: "users-fill",
      text: "Sub Admin",
      link: "/subadmin",
    },
    {
      icon: "user-round",
      text: "Dentist",
      link: "/Dentist",
    },
    {
      icon: "user-list-fill",
      text: "User",
      link: "/user",
    },
  ],
  [
    {
      icon: "user-round",
      text: "Dentist",
      link: "/Dentist",
    },
    {
      icon: "user-list-fill",
      text: "User",
      link: "/user",
    },
  ],
];
console.log(Card[0], "menu");

export default Card;
