/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import Layout from "../../../layout/Index";
import axios from "axios";
import "../../../pages/pages.css";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import BaseUrl from "../../../BaseURL/BaseUrl";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { TableBody, TableCell, TableRow } from "@material-ui/core";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Contact = () => {
  const classes = useStyles();

  const [loader, setLoaders] = useState(false);
  const [api, setApi] = useState([]);
  const contact = () => {
    setLoaders(true);

    const token = localStorage.getItem("accesstoken");
    var config = {
      method: "get",
      url: `${BaseUrl.baseurl}/contact?doctor=doctor`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoaders(false);
        console.log(response, "Doctor");
        setApi(response?.data?.data);
      })
      .catch(function (error) {
        setLoaders(false);

        console.log(error);
      });
  };

  useEffect(() => {
    contact();
  }, []);

  const renderRow = (rowData, index) => {
    console.log(rowData, "kvdkv");
    return (
      <TableRow>
        <TableCell>{rowData?.id}</TableCell>
        <TableCell>{rowData?.name}</TableCell>
        <TableCell>{rowData.email}</TableCell>
        <TableCell>{rowData?.subject}</TableCell>
        <TableCell>{rowData?.queries}</TableCell>
        <TableCell>{rowData?.doctor?.name}</TableCell>
        <TableCell>{rowData?.doctor?.email}</TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Layout>
        <Head title="Contact Dashboard" />
        <Content>
          {loader === true ? (
            <Backdrop className={classes.backdrop} open={loader}>
              <CircularProgress color="red" />
            </Backdrop>
          ) : null}
          <MaterialTable
            icons={tableIcons}
            title="Dentist Contact List"
            columns={[
              { title: "User Id", field: "id" },
              { title: "Name", field: "name" },
              { title: "Email", field: "email" },
              { title: "Subject", field: "subjeccst" },
              { title: "Queries", field: "queries" },
              {
                title: "doctor Name",
                field: "name",
              },
              {
                title: "doctor Email",
                field: "email",
              },
            ]}
            data={api}
            components={{
              // Override the table body rendering
              Body: (api) => (
                <TableBody>
                  {api.renderData.map((rowData, index) =>
                    renderRow(rowData, index)
                  )}
                </TableBody>
              ),
            }}
            // actions={[
            //   {
            //     icon: () => <AddBox className="text-theme" />,
            //     tooltip: "Add Doctor",
            //     isFreeAction: true,
            //     onClick: (event) => {
            //       history.push("/adddoctor");
            //     },
            //   },
            //   {
            //     icon: () => <Edit className="text-theme " />,
            //     tooltip: "Edit Doctor",
            //     onClick: (event, rowData) =>
            //       history.push("/editdoctor/" + rowData.id),
            //   },
            //   (rowData) => ({
            //     icon: () => <DeleteOutline className="text-theme" />,
            //     tooltip: "Delete Doctor",
            //     // onClick: (event, rowData) => deldata(rowData),
            //   }),
            // ]}
            // options={{
            //   actionsColumnIndex: -1,
            // }}
          />
        </Content>
      </Layout>
    </>
  );
};

export default Contact;
